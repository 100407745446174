import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'shared-components/icon';
import { Text } from 'shared-components/text';

import { Col } from 'reactstrap';

const FatalError = ({ icons, title, description, children }) => (
  <section className="p-1_5 text-gray-darker bg-blue-dark h-100 w-100 d-flex flex-column justify-content-between">
    {children && children[0]}
    <main className="fatal-error__content">
      <Col
        sm="12"
        lg={{ size: 6, offset: 3 }}
        className="h-100 p-5 rounded d-flex flex-column align-items-center justify-content-around bg-white"
      >
        <div className="d-flex flex-column align-items-center flex-shrink-0">
          {icons.map(icon => (
            <Icon key={icon.name} className="mb-0_5 fatal-error__icon" {...icon} />
          ))}
          <Text tag="p" className="text-center mt-0_5 h1 fatal-error__title" bold>
            {title}
          </Text>
        </div>
        <div className="d-flex flex-column align-items-center flex-shrink-0 mt-0_5">
          <Text tag="p" className="mb-1_5 text-center fatal-error__description">
            {description}
          </Text>
          {children && children[1]}
        </div>
      </Col>
    </main>
    <footer className="text-center">
      <Text color="white font-weight-semibold">&#9400; Edmunds.com, Inc., CarCode</Text>
    </footer>
  </section>
);

FatalError.propTypes = {
  icons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      size: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default FatalError;
