import { graphql } from '@apollo/client/react/hoc';
import { compose, mapProps } from 'react-recompose';

import { withLoggedUser } from 'core/hocs/logged-user/index';
import { withRouter } from 'react-router-dom';

import { ALL_ACTIVE_FEATURES } from 'core/queries/features-all';
import { USER_FEATURES } from 'core/queries/features-user';
import NotificationsNavigationContainer from 'components/navigation/notifications-navigation/notifications-navigation.container';

export default compose(
  withLoggedUser,
  withRouter,
  graphql(ALL_ACTIVE_FEATURES, {
    name: 'allFeatures',
    options: () => ({
      fetchPolicy: 'cache-and-network',
    }),
  }),
  graphql(USER_FEATURES, {
    name: 'userFeatures',
    options: ({ loggedUser: { id: userId } }) => ({
      fetchPolicy: 'cache-and-network',
      variables: { userId },
    }),
  }),
  mapProps(
    ({
      allFeatures: { getAllActiveFeatures: allFeatures },
      userFeatures: { getUserFeatureNotifications: userFeatures },
      ...props
    }) => ({
      allFeatures,
      userFeatures,
      ...props,
    }),
  ),
)(NotificationsNavigationContainer);
