import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const SlideAndCollapse = ({ animate, flexFrom, flexTo, xFrom, xTo, children, className }) => {
  const variants = {
    open: { flexBasis: flexTo, x: xTo },
    collapsed: { flexBasis: flexFrom, x: xFrom },
  };

  const defaultBehavior = {
    initial: 'collapsed',
    animate: 'open',
    exit: 'collapsed',
  };

  const animated = {
    initial: false,
    animate,
  };

  const setUp = animate ? animated : defaultBehavior;

  return (
    <motion.div
      variants={variants}
      transition={{
        ease: 'linear',
      }}
      className={className}
      {...setUp}
    >
      {children}
    </motion.div>
  );
};

SlideAndCollapse.defaultProps = {
  animate: '',
  flexFrom: '',
  flexTo: '',
  xFrom: '',
  xTo: '',
  children: [],
  className: '',
};

SlideAndCollapse.propTypes = {
  animate: PropTypes.string,
  flexFrom: PropTypes.string,
  flexTo: PropTypes.string,
  xFrom: PropTypes.string,
  xTo: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
};

export default SlideAndCollapse;
