import { graphql } from '@apollo/client/react/hoc';
import { compose } from 'react-recompose';

import { withRouter } from 'react-router-dom';

import { DISABLE_AUTO_ASSIGNMENT_MUTATION } from 'core/mutations/auto-assign';

import { withLoggedUser } from 'core/hocs/logged-user';

import LogoutContainer from './logout.container';

export default compose(
  withLoggedUser,
  withRouter,
  graphql(DISABLE_AUTO_ASSIGNMENT_MUTATION, {
    name: 'disableAutoAssignment',
  }),
)(LogoutContainer);
