import React from 'react';
import ReactDOM from 'react-dom';

import { parseConfig } from 'core/config/config';
import { urlUtils } from 'core/utils/url';
import { init as initApi } from 'core/api/api';
import { googleAnalyticsService } from 'core/tracking/google-analytics';
import { register as registerSw } from 'core/sw/service-worker-loader';
import { newRelicService } from 'core/new-relic';

import App from './index.jsx';

import './styles/bootstrap.scss';

newRelicService.init();

window.carcode = {
  version: process.env.ARTIFACT_VERSION,
};

fetch('/carcode-call-center.properties')
  .then(response => response.text())
  .then(config => {
    const parsedConfig = parseConfig(config);
    const isExternal = urlUtils.isExternalHostname();
    const updatedConfig = {
      restEndpoint: isExternal ? parsedConfig.restEndpointExt : parsedConfig.restEndpoint,
      wsEndpoint: isExternal ? parsedConfig.wsEndpointExt : parsedConfig.wsEndpoint,
      env: parsedConfig.env,
    };

    initApi(updatedConfig);

    const { ga4TrackingID, ga4TrackingIdDealer, ga4TrackingIdManaged } = parsedConfig;
    if (ga4TrackingID) {
      const { host } = window.location;
      switch (host) {
        case 'dealer.carcode.com':
          parsedConfig.ga4TrackingID = ga4TrackingIdDealer;
          break;
        case 'managed.carcode.com':
          parsedConfig.ga4TrackingID = ga4TrackingIdManaged;
          break;
        default:
          break;
      }
    }

    googleAnalyticsService.init(parsedConfig);

    if (newRelicService.isNewRelicLoaded()) {
      newRelicService.setCustomAttribute('env', parsedConfig.env);
      newRelicService.setCustomAttribute('ecs_service', parsedConfig.serverGroup);
    }

    try {
      registerSw();
    } catch (e) {
      newRelicService.ignoreError(e);
    }
  })
  .then(() => {
    ReactDOM.render(
      <App />, // eslint-disable-line
      document.getElementById('root'),
    );
  });
