import React from 'react';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { mapProps } from 'react-recompose';

import { FONT_SIZES } from 'core/constants/font-size';

import SearchBar from './search-bar.presentation';

class SearchBarContainer extends React.PureComponent {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    iconSize: PropTypes.oneOf(FONT_SIZES),
  };

  static defaultProps = {
    iconSize: 'lg',
  };

  state = {
    isActive: false,
  };

  iconSizes = {
    base: '0.875rem',
    lg: '1rem',
    sm: '0.75rem',
    xs: '0.6rem',
  };

  handleSearchChange = e => {
    this.setState({
      searchText: e.currentTarget.value.trim(),
    });

    this.props.handleSubmit(e.currentTarget.value.trim());
  };

  handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    this.props.handleSubmit(this.state.searchText);
  };

  handleFocus = () => {
    this.setState({
      isActive: true,
    });
  };

  handleBlur = () => {
    this.setState({
      isActive: !!this.state.searchText,
    });
  };

  render() {
    const { isActive } = this.state;

    return (
      <SearchBar
        {...this.props}
        isActive={isActive}
        iconSize={this.iconSizes[this.props.iconSize]}
        handleSubmit={this.handleSubmit}
        handleSearchChange={this.handleSearchChange}
        handleFocus={this.handleFocus}
        handleBlur={this.handleBlur}
      />
    );
  }
}

export { SearchBarContainer };

export default mapProps(({ handleSubmit, ...props }) => ({
  handleSubmit: _.debounce(handleSubmit, 200),
  ...props,
}))(SearchBarContainer);
