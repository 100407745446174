import LoggedUser from './logged-user';
import UserPersonalInfo from './user-personal-info';
import UserProfile from './user-profile';
import UserSchedule from './user-schedule';
import UsersListFields from './users-list-fields';

export default {
  LoggedUser,
  UserPersonalInfo,
  UserProfile,
  UserSchedule,
  UsersListFields,
};
