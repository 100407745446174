import React from 'react';
import PropTypes from 'prop-types';

import { pure } from 'react-recompose';
import classNames from 'classnames';

import { TestPropTypes } from 'core/prop-types';

import { LoadingButton } from 'shared-components/loading-button';

import { Button, Modal as ReactstrapModal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const Modal = ({
  title,
  submitText,
  cancelText,
  children,
  className,
  isLoading,
  isDisabled,
  stretch,
  bodyClassname,
  footerClassname,
  dataTestAttributes,
  type,
  hideSubmitButton,
  hideCancelButton,
  handleSubmit,
  handleCancel,
  handleToggle,
  submitButtonClass,
  ...props
}) => (
  <ReactstrapModal
    className={classNames(className, 'text-gray-darkest', {
      'modal-dialog-stretch': stretch,
    })}
    toggle={handleToggle}
    {...props}
  >
    {title && (
      <ModalHeader
        toggle={handleToggle}
        className={classNames('text-white py-1', {
          'bg-gray-darkest': !type,
          [`bg-${type}`]: type,
        })}
      >
        {title}
      </ModalHeader>
    )}
    <ModalBody className={bodyClassname}>{children}</ModalBody>
    {(!hideCancelButton || !hideSubmitButton) && (
      <ModalFooter className={classNames('border-top border-gray-light py-1 flex-shrink-0', footerClassname)}>
        {!hideCancelButton && (
          <Button color="gray" className="rounded-oval text-white flex-grow-1 flex-lg-grow-0" onClick={handleCancel}>
            {cancelText}
          </Button>
        )}
        {!hideSubmitButton && (
          <LoadingButton
            color="primary"
            className={submitButtonClass || 'rounded-oval flex-grow-1 flex-lg-grow-0'}
            onClick={handleSubmit}
            isLoading={isLoading}
            isDisabled={isDisabled}
            data-test={dataTestAttributes.submit}
          >
            {submitText}
          </LoadingButton>
        )}
      </ModalFooter>
    )}
  </ReactstrapModal>
);

Modal.defaultProps = {
  title: '',
  submitText: 'Save',
  cancelText: 'Cancel',
  className: '',
  isLoading: false,
  isDisabled: false,
  stretch: false,
  bodyClassname: '',
  footerClassname: '',
  type: '',
  hideSubmitButton: false,
  hideCancelButton: false,
  dataTestAttributes: {
    submit: '',
  },
  handleCancel: null,
  handleSubmit: null,
  submitButtonClass: null,
};

Modal.propTypes = {
  title: PropTypes.string,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  handleToggle: PropTypes.func.isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  stretch: PropTypes.bool,
  hideSubmitButton: PropTypes.bool,
  hideCancelButton: PropTypes.bool,
  bodyClassname: PropTypes.string,
  footerClassname: PropTypes.string,
  type: PropTypes.string,
  dataTestAttributes: TestPropTypes.DataTestAttributes,
  submitButtonClass: PropTypes.string,
};

export default pure(Modal);
