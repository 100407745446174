import React from 'react';
import PropTypes from 'prop-types';

import { pure } from 'react-recompose';
import classNames from 'classnames';

import './logo.styles.scss';

const Logo = ({ className, large, white, ...props }) => (
  <div
    {...props}
    className={classNames('logo', className, {
      'logo--large': large,
      'logo--white': white,
    })}
  />
);

Logo.defaultProps = {
  className: '',
  large: false,
  white: false,
};

Logo.propTypes = {
  className: PropTypes.string,
  large: PropTypes.bool,
  white: PropTypes.bool,
};

export default pure(Logo);
