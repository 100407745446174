// eslint-disable-next-line
const appPackageJson = require('../../../../package.json');

const getAppVersion = () => process.env.ARTIFACT_VERSION || appPackageJson.version;

const getAppEnv = () => process.env.NODE_ENV;

export const isProd = () =>
  window.location.href.startsWith('https://dealer.carcode.com') ||
  window.location.href.startsWith('https://managed.carcode.com');

export default {
  getAppVersion,
  getAppEnv,
  isProd,
};
