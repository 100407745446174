import { compose } from 'react-recompose';

import { CLOSE_REASON } from 'core/constants/web-socket';

import withPingPong from './with-ping-pong';
import withAutoReconnect from './with-auto-reconnect';
import withUuidTracking from './with-uuid-tracking';

import WebSocket from './WebSocket';

const Socket = compose(withUuidTracking, withPingPong, withAutoReconnect)(WebSocket);

/**
 * Manage WebSocket connections
 */
class WebSocketManager {
  constructor() {
    this.webSockets = {};
  }

  /**
   * Creates a new WebSocket instance
   * @param {String} wsUrl
   * @param {User} user
   */
  init({ wsUrl, user }) {
    const webSocket = new Socket({ wsUrl, user });

    this.webSockets[wsUrl] = webSocket;
  }

  /**
   * Checks if ws has at least one event
   * @param {String} wsUrl
   */
  isEmpty(wsUrl) {
    const webSocket = this.webSockets[wsUrl];

    return webSocket.isEmpty();
  }

  /**
   * Checks to see if a wsUrl (channel) is already subscribed
   * @param wsUrl
   * @returns {boolean}
   */
  isSubscribed(wsUrl) {
    return this.webSockets[wsUrl];
  }

  /**
   * Checks to see if a wsUrl (channel) has a readyState set to WebSocket.OPEN
   * @param wsUrl
   * @returns {boolean}
   */
  isSocketOpen(wsUrl) {
    return this.webSockets[wsUrl].is(window.WebSocket.OPEN);
  }

  /**
   * Checks to see if a connection exists in the webSockets object and is in readyState WebSocket.OPEN
   * @param wsUrl
   * @returns {boolean}
   */
  isReady(wsUrl) {
    return this.isSubscribed(wsUrl) && this.isSocketOpen(wsUrl);
  }

  /**
   * Manually closes a connection
   * @param wsUrl
   * @param code
   * @param reason
   */
  disconnect(wsUrl, code, reason) {
    this.webSockets[wsUrl].disconnect(code, reason);
  }

  /**
   * Sends a message if a connection isReady
   * @param wsUrl
   * @param data
   */
  sendMessage(wsUrl, data) {
    this.webSockets[wsUrl].sendMessage(data);
  }

  /**
   * Sends JSON encoded data to a connection
   * @param wsUrl
   * @param data
   */
  sendData(wsUrl, data) {
    this.webSockets[wsUrl].sendData(data);
  }

  /**
   * allows registering an `on` events for a connection if not passed in when subscribed
   * @param wsUrl
   * @param event
   * @param handlerRef
   */
  registerEvents(wsUrl, events) {
    this.webSockets[wsUrl].registerEvents(events);
  }

  /**
   * allows unregistering an `on` events
   * @param wsUrl
   * @param event
   * @param handlerRef
   */
  unregisterEvents(wsUrl, events) {
    this.webSockets[wsUrl].unregisterEvents(events);
  }

  /**
   * Subscribes a new wsUrl (channel) by adding it to the webSockets object
   * @param wsUrl websocket URL
   * @param onMessage
   * @param onError
   * @param onOpen
   * @param onClose
   * @param urlPath url path without leading forward slash
   *
   * @returns {Function} unsubscribe function
   */
  subscribe({ wsUrl, user, onMessage, onError, onOpen, onClose }) {
    if (!window.WebSocket) {
      return null;
    }

    if (!this.isSubscribed(wsUrl)) {
      this.init({
        wsUrl,
        user,
      });
    }

    const events = [
      {
        name: 'onopen',
        handler: onOpen,
      },
      {
        name: 'onerror',
        handler: onError,
      },
      {
        name: 'onmessage',
        handler: onMessage,
      },
      {
        name: 'onclose',
        handler: onClose,
      },
    ];

    this.registerEvents(wsUrl, events);

    // unsubscribe function
    return () => {
      this.unregisterEvents(wsUrl, events);

      // if no subscription anymore then unsubscrive
      if (this.isEmpty(wsUrl)) {
        this.unsubscribe(wsUrl);
      }
    };
  }

  /**
   * Unsubscribes a ws
   * @param {String} wsUrl
   */
  unsubscribe(wsUrl) {
    this.disconnect(wsUrl, 1000, CLOSE_REASON.EXPECTED);

    this.webSockets[wsUrl] = null;
  }
}

export default WebSocketManager;
