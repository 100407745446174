import { cookieService } from 'core/cookie';

import { NOT_SUPPORTED_REASON, BROWSER } from 'core/constants/browser';

const get = () => {
  if (navigator.userAgent.includes('Chrome')) {
    return BROWSER.CHROME;
  } else if (navigator.userAgent.includes('Opera')) {
    // eslint-disable-line
    return BROWSER.OPERA;
  } else if (navigator.userAgent.includes('MSIE')) {
    return BROWSER.IE;
  } else if (navigator.userAgent.includes('Firefox')) {
    return BROWSER.MOZILA;
  } else if (navigator.userAgent.includes('Safari')) {
    return BROWSER.SAFARI;
  }

  return BROWSER.UNKNOWN;
};

const isSupported = () => {
  if (!cookieService.areEnabled) {
    return {
      supported: false,
      reason: NOT_SUPPORTED_REASON.COOKIES_DISABLED,
    };
  }

  return {
    supported: true,
  };
};

export default {
  isSupported,
  get,
};
