import { withProps, compose } from 'react-recompose';

import { userService } from 'core/user';
import { withLoggedUser } from 'core/hocs/logged-user';

import Permissions from './permissions.presentation.jsx';

export default compose(
  withLoggedUser,
  withProps(({ permissionsCheckName = 'hasPermissions', loggedUser: user, ...restProps }) => ({
    isAllowed: userService[permissionsCheckName]({ ...restProps, user }),
  })),
)(Permissions);
