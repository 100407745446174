import React from 'react';
import PropTypes from 'prop-types';

import { compose } from 'react-recompose';
import { withRouter } from 'react-router-dom';

import { Collapse, Nav } from 'reactstrap';

import { UiPropTypes } from 'core/prop-types';

import NavigationItem from './navigation-item.presentation';

class NavigationItemContainer extends React.Component {
  static propTypes = {
    navItems: PropTypes.arrayOf(UiPropTypes.NavigationItem),
    className: PropTypes.string,
    linkClassName: PropTypes.string,
    activeLinkClassName: PropTypes.string,
    handleToggle: PropTypes.func.isRequired,
    isButton: PropTypes.bool,
    to: PropTypes.string.isRequired,
    location: PropTypes.shape({}).isRequired,
  };

  static defaultProps = {
    navItems: [],
    className: '',
    linkClassName: '',
    activeLinkClassName: '',
    isButton: false,
  };

  state = {
    isOpened: false,
    path: '',
  };

  componentDidMount() {
    // open/close menu under button depending on current location
    if (this.props.isButton) {
      this.setOpened(this.props.location.pathname.includes(this.props.to));
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isButton) {
      // memorize location for updating state
      if (prevState.path !== this.props.location.pathname) {
        this.updateLocation();
      }

      // close menu under button if user went away to other page
      if (prevState.path.includes(this.props.to) && !this.props.location.pathname.includes(this.props.to)) {
        this.setOpened(false);
      }
    }
  }

  setOpened = opened => {
    this.setState({ isOpened: opened });
  };

  updateLocation = () => {
    this.setState({ path: this.props.location.pathname });
  };

  toggleOpened = e => {
    e.preventDefault();
    this.setState({ isOpened: !this.state.isOpened });
  };

  renderChildren = ({ match }) => (
    <Collapse isOpen={!!match || (this.props.isButton && this.state.isOpened)} className="mr-2">
      {this.props.navItems && this.props.navItems.length ? (
        <Nav navbar className={'mb-0_5'}>
          {this.props.navItems.map(navItem =>
            navItem.component ? (
              React.cloneElement(navItem.component, {
                onClick: navItem.onClick,
                key: navItem.to,
              })
            ) : (
              <NavigationItem
                {...navItem}
                key={navItem.to}
                onClick={navItem.onClick}
                className={`${this.props.className} w-100 mr-1`}
                linkClassName="pl-3"
              />
            ),
          )}
        </Nav>
      ) : (
        this.renderWithOnCategoryClick()
      )}
    </Collapse>
  );

  renderWithOnCategoryClick = () => {
    const { handleToggle, children } = this.props;

    if (React.isValidElement(children)) {
      return React.cloneElement(children, {
        onCategoryClick: handleToggle,
      });
    }

    return null;
  };

  render() {
    const { children, navItems, isButton, ...props } = this.props;
    const { isOpened } = this.state;

    return (
      <NavigationItem
        {...props}
        isButton={isButton}
        onClick={isButton ? this.toggleOpened : null}
        isOpened={isOpened}
        linkClassName="mb-0_5"
        renderChildren={this.renderChildren}
      />
    );
  }
}

export { NavigationItemContainer };

export default compose(withRouter)(NavigationItemContainer);
