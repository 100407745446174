import React from 'react';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';

const ShowAndHide = ({ heightFrom, heightTo, opacityFrom, opacityTo, children, className }) => {
  const variants = {
    open: { height: heightTo, opacity: opacityTo },
    collapsed: { height: heightFrom, opacity: opacityFrom },
  };
  return (
    <motion.div
      initial="collapsed"
      animate="open"
      exit="collapsed"
      variants={variants}
      transition={{
        ease: 'linear',
      }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

ShowAndHide.defaultProps = {
  heightFrom: '0px',
  heightTo: 'auto',
  opacityFrom: 0,
  opacityTo: 1,
  children: [],
  className: '',
};

ShowAndHide.propTypes = {
  heightFrom: PropTypes.string,
  heightTo: PropTypes.string,
  opacityFrom: PropTypes.number,
  opacityTo: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
};

export default ShowAndHide;
