import { compose, mapProps } from 'react-recompose';
import { withRouter } from 'react-router-dom';
import { graphql } from '@apollo/client/react/hoc';
import _ from 'lodash';

import { GET_UNREAD } from 'core/queries/inquiries-unread';
import { withLoggedUser } from 'core/hocs/logged-user';
import { SWITCH_DEALER_MUTATION } from 'core/mutations/logged-user/index';
import { CLEAR_BACKGROUND_INQUIRIES_CLIENT_MUTATION } from 'core/mutations/inquiries-background';
import SwitchDealerContainer from './switch-dealer.container';

export default compose(
  withLoggedUser,
  withRouter,
  graphql(GET_UNREAD, {
    options: ({ loggedUser: { id: userId } }) => ({
      fetchPolicy: 'cache-and-network',
      variables: { userId },
    }),
  }),
  mapProps(({ loggedUser: { dealers, currentDealer, ...rest }, data: { unread, refetch }, ...props }) => ({
    dealers: _.reject(dealers, d => d.id === currentDealer.id),
    currentDealer,
    loggedUser: {
      dealers,
      currentDealer,
      ...rest,
    },
    unread,
    refetch,
    ...props,
  })),
  graphql(SWITCH_DEALER_MUTATION),
  graphql(CLEAR_BACKGROUND_INQUIRIES_CLIENT_MUTATION, {
    name: 'clearBackgroundInquiries',
    options: {
      fetchPolicy: 'no-cache',
    },
  }),
)(SwitchDealerContainer);
