import BackgroundInquiry from './background-inquiry';
import InquiryContextSettings from './inquiry-context-settings';
import Inquiry from './inquiry';
import MessageStatistics from './message-statistics';
import TransferDealer from './transfer-dealer';

export default {
  BackgroundInquiry,
  InquiryContextSettings,
  Inquiry,
  MessageStatistics,
  TransferDealer,
};
